import React from "react";
import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
  Grid,
  Stack,
} from "@mui/material";

import "./CustomersSection.css";

import retailers_popout from "../assets/retailers_popout.png";
import solar_installers_popout from "../assets/solar_installers_popout.png";
import landlords_popout from "../assets/landlords_popout.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CustomersSection() {
  let heroPicStyle = {
    maxWidth: "100%",
    height: "auto",
    padding: 0,
    margin: 0,
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box style={{ backgroundColor: "#F3FCFF" }} py={20}>
      <Container maxWidth="lg">
        <Typography variant="h3" align="center" mb={5} mt={2} px={2}>
          We help people take advantage of renewables
        </Typography>
        <Box sx={{ width: "100%" }} px={1}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="fullWidth"
            >
              <Tab label="Energy Retailers" {...a11yProps(0)} />
              <Tab label="Solar Installers" {...a11yProps(1)} />
              <Tab label="Landlords" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Grid container mt={2}>
              <Grid
                container
                border={"0px solid red"}
                sm={12}
                md={6}
                direction="column"
                justifyContent={"center"}
                alignItems="center"
              >
                <Stack border={"0px solid green"}>
                  <Typography align="left" variant="h6" color="#A3A3A3">
                    Energy Retailers
                  </Typography>
                  <Typography
                    align="left"
                    variant="h4"
                    color={"#77C9E3"}
                    mt={1}
                    mb={3}
                  >
                    Win customers to your renewable energy plans
                  </Typography>
                  <Typography align="left">
                    Use our leading bill comparison software to win new
                    customers with compelling data driven insights.
                    <br />
                    <br />
                    Show customers how your plans can deliver cleaner and
                    cheaper energy than the big energy companies.
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                border={"0px solid red"}
                sm={12}
                md={6}
                px={{ xs: 0, sm: 0 }}
              >
                <img
                  src={retailers_popout}
                  alt="retailer popout"
                  style={heroPicStyle}
                  // width={"100%"}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container mt={2}>
              <Grid
                container
                border={"0px solid red"}
                sm={12}
                md={6}
                direction="column"
                justifyContent={"center"}
                alignItems="center"
              >
                <Stack border={"0px solid green"}>
                  <Typography align="left" variant="h6" color="#A3A3A3">
                    Solar Installers
                  </Typography>
                  <Typography
                    align="left"
                    variant="h4"
                    color={"#77C9E3"}
                    mt={1}
                    mb={3}
                  >
                    Stand out from the crowd
                  </Typography>
                  <Typography align="left">
                    Don’t just be another solar installer delivering
                    cookie-cutter sales proposals. Our platform lets you size
                    solar and battery systems based on customers smart meter
                    data and retail pricing.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item border={"0px solid red"} sm={12} md={6} px={2}>
                <img
                  src={solar_installers_popout}
                  alt="solar popout"
                  style={heroPicStyle}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid container mt={2}>
              <Grid
                container
                border={"0px solid red"}
                sm={12}
                md={6}
                direction="column"
                justifyContent={"center"}
                alignItems="center"
              >
                <Stack border={"0px solid green"}>
                  <Typography align="left" variant="h6" color="#A3A3A3">
                    Landlords
                  </Typography>
                  <Typography
                    align="left"
                    variant="h4"
                    color={"#77C9E3"}
                    mt={1}
                    mb={3}
                  >
                    Boost returns and save your tenants money
                  </Typography>
                  <Typography align="left">
                    Some people think there’s no way for renters to get
                    renewable energy.
                    <br />
                    <br />
                    Our platform allows commercial property owners to install
                    solar and batteries, and sell the renewable energy to their
                    tenants.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item border={"0px solid red"} sm={12} md={6} px={2}>
                <img
                  src={landlords_popout}
                  alt="landlord popout"
                  style={heroPicStyle}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      </Container>
    </Box>
  );
}

export default CustomersSection;
