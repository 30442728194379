import React from "react";
// import { Container, Box, Hidden } from "@mui/material";
import HomeHeader from "../components/HomeHeader";
import PlatformExplanation from "../components/PlatformExplanation";
import FeaturesSection from "../components/FeaturesSection";
import CustomersSection from "../components/CustomersSection";
import ImpactSection from "../components/ImpactSection";
// import VideoSection from "../components/VideoSection";
// import ProductDemos from "../components/ProductDemos";

function Home() {
  return (
    <>
      <HomeHeader />
      {/* <VideoSection /> */}
      <PlatformExplanation />
      <FeaturesSection />
      <CustomersSection />
      <ImpactSection />
      {/* <ProductDemos /> */}
    </>
  );
}

export default Home;
