import { createTheme, responsiveFontSizes } from "@mui/material/styles";

import "@fontsource/rubik/300.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/700.css";
import "@fontsource/rubik/800.css";
import "@fontsource/rubik/900.css";

export const theme = responsiveFontSizes(
  createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            color: "#77c9e3",
          },
          colorPrimary: {
            "&.Mui-checked": {
              color: "#77c9e3",
            },
          },
          track: {
            backgroundColor: "#77c9e3",
            ".Mui-checked.Mui-checked + &": {
              backgroundColor: "#77c9e3",
            },
          },
        },
      },
    },
    palette: {
      type: "light",
      primary: {
        main: "#77c9e3",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#81F1C5",
        contrastText: "#8496A6",
      },
      tertiary: {
        main: "#2b265a",
      },
      info: {
        main: "#FFFFFF",
      },
      text: {
        primary: "#000000",
      },
    },
    typography: {
      fontFamily: "rubik",
      h1: {
        fontWeight: 900,
        fontSize: "56px",
        color: "#000000",
      },
      h2: {
        fontWeight: 900,
        fontSize: "56px",
        color: "#FFFFFF",
      },
      h3: {
        fontWeight: 700,
        fontSize: "40px",
        color: "#2B265A",
      },
      h4: {
        fontWeight: 700,
        fontSize: "30px",
        color: "#FFFFFF",
      },
      h5: {
        fontWeight: 400,
        fontSize: "30px",
      },
      subtitleText: {
        fontWeight: 400,
        fontSize: "20px",
        color: "#2B84A1",
      },
      energyUsageCardHeadings: {
        fontWeight: 700,
        fontSize: "32px",
      },
      energyUsageCardContent: {
        fontWeight: 400,
        fontSize: "15px",
        color: "#2B265A",
        lineHeight: "22px",
      },
      energyUsageCardLinks: {
        fontWeight: 700,
        fontSize: "15px",
        color: "#16436B",
      },
      billComparisonContent: {
        fontWeight: 500,
        fontSize: "18px",
        color: "#2B265A",
        letterSpacing: "0.014em",
        lineHeight: "36px",
      },
      mythFactCardText: {
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "20px",
      },
      mythFactCardMobileHeader: {
        color: "#FFFFFF",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "30px",
      },
      mythFactCardMobileText: {
        fontWeight: 600,
        fontSize: "10px",
        lineHeight: "16px",
      },
      plansCardText: {
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "35px",
        color: "#2B265A",
      },
      getStartedText: {
        fontWeight: 300,
        fontSize: "18px",
        lineHeight: "24px",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1110,
        lg: 1200,
        xl: 1536,
      },
    },
  })
);
