import logo from "../assets/gleen_logo.png";
import house_background from "../assets/hero_background.png";
import {
  Button,
  Box,
  IconButton,
  Stack,
  Link,
  AppBar,
  Toolbar,
  Hidden,
  SwipeableDrawer,
  Divider,
  List,
  ListItem,
} from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { NavLink } from "react-router-dom";
import { Container } from "@mui/system";
import { useState } from "react";
import Slide from "@mui/material/Slide";

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const navigationLinks = [
  // { name: "Pricing", href: "todo" },
  // { name: "Features", href: "todo" },
  // { name: "About Us", href: "todo" },
  // { name: "Blog", href: "todo" },
  // { name: "Help", href: "todo" },
];

let activeLinkStyle = {
  color: "#FFFFFF",
  fontWeight: 500,
  fontSize: "14px",
  textDecoration: "underline",
};
let inactiveLinkStyle = {
  color: "#FFFFFF",
  fontWeight: 500,
  fontSize: "14px",
  textDecoration: "none",
};

function Navbar() {
  const [open, setOpen] = useState(false);

  return (
    <HideOnScroll>
      <AppBar
        position="sticky"
        sx={{
          color: "#77C9E3",
          backgroundColor: "#77C9E3",
          backgroundImage: `url(${house_background})`,
          boxShadow: "none",
          py: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
            <Hidden mdUp>
              {/* <IconButton sx={{ color: "white" }} onClick={() => setOpen(true)}>
                <MenuIcon />
              </IconButton> */}
            </Hidden>
            <Box component={Link} href="/" pr={2}>
              <img
                style={{ display: "block", height: 40 }}
                src={logo}
                alt="logo"
                className="logo"
              />
            </Box>
            <Hidden mdDown>
              <Stack
                direction="row"
                alignItems={"center"}
                justifyContent="space-evenly"
                width="60%"
                // py={6}
              >
                {navigationLinks.map((link) => {
                  return (
                    <Link
                      key={link.name}
                      component={NavLink}
                      to={link.href}
                      style={({ isActive }) =>
                        isActive ? activeLinkStyle : inactiveLinkStyle
                      }
                    >
                      {link.name}
                    </Link>
                  );
                })}
              </Stack>
              <Button
                variant="outlined"
                size="medium"
                sx={{ borderRadius: 50, px: 2, py: 1 }}
                href={`mailto:${"hello@gleen.au"}?subject=${"Demo Enquiry"}&body=${""}`}
              >
                Schedule a Demo
              </Button>
              <Button
                variant="contained"
                size="medium"
                sx={{ borderRadius: 50, px: 2, ml: 2, py: 1 }}
                href={`mailto:${"hello@gleen.au"}?subject=${"Hello"}&body=${""}`}
              >
                Contact
              </Button>
            </Hidden>
            <Hidden mdUp>
              <Box flexGrow={1} />
              <Button variant="contained" sx={{ py: 1, borderRadius: 5 }}>
                Contact
              </Button>
            </Hidden>
          </Toolbar>
        </Container>
        <SwipeableDrawer
          anchor="left"
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
        >
          <div
            onClick={() => setOpen(false)}
            onKeyPress={() => setOpen(false)}
            role="button"
            tabIndex={0}
          >
            <IconButton>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {navigationLinks.map((item) => (
              <ListItem key={item.name}>
                <Link
                  key={item.name}
                  color="black"
                  variant="button"
                  underline="none"
                  href={item.href}
                >
                  {item.name}
                </Link>
              </ListItem>
            ))}
          </List>
        </SwipeableDrawer>
      </AppBar>
    </HideOnScroll>
  );
}

export default Navbar;
