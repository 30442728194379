import React from "react";
import {
  Box,
  Container,
  Typography,
  Stack,
  Grid,
  Divider,
} from "@mui/material";

import retailer_pic_1 from "../assets/retailer_pic_1.png";
import cdr_pic_2 from "../assets/cdr_pic_2.png";
import risingprices from "../assets/risingprices.png";

function ImpactSection() {
  let heroPicStyle = {
    // maxWidth: "100%",
    // height: "auto",
    // padding: 0,
    // margin: 0,
    // position: "sticky",
    // top: "10rem",
    width: "100%",
  };
  return (
    <Box style={{ backgroundColor: "#F3FCFF" }}>
      <Container maxWidth="lg">
        <Stack border={"0pxsolid red"} mb={10}>
          <Typography variant="h3" align="left" px={2} pb={3}>
            Why it's time to make the switch
          </Typography>
          <Divider />
          {/* <Typography variant="subtitleText">
            Why do we do what we do? Why now?
          </Typography> */}
        </Stack>

        <Grid container display="flex" mb={10} border={"0px solid blue"}>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            px={2}
            border={"0px solid green"}
            order={{ xs: 1, sm: 0 }}
            // display="flex"
            alignSelf="center"
            className="hidden"
          >
            <Stack border={"0px solid blue"}>
              <Typography variant="h4" color={"#77C9E3"} align="left" pb={5}>
                Renewables are disrupting the traditional energy industry
              </Typography>
              <Typography align="left">
                The Australian energy industry is dominated by a small number of
                giant coal powered energy companies like AGL, Origin and Energy
                Australia.
                <br />
                <br />
                They have used their dominant position and incumbency to lock
                competitors out of the market and to slow the transition to
                renewable energy.
                <br />
                <br />
                Desipte this the world is moving on and rapidly building out new
                renewable energy supplies.
                <br />
                <br />
                Our SaaS platform enables disruptive new energy businesses to
                take on the Big Energy companies (and win).
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            border={"0px solid red"}
            px={{ xs: 6, sm: 10 }}
            pb={{ xs: 4, sm: 0 }}
          >
            <img
              src={retailer_pic_1}
              border={"0px solid blue"}
              alt="Hero"
              style={heroPicStyle}
            ></img>
          </Grid>
        </Grid>
        <Grid container display="flex" mb={10} pb={0}>
          <Grid
            item
            lg={6}
            md={6}
            border={"0px solid red"}
            px={{ xs: 6, sm: 10 }}
            pb={{ xs: 4, sm: 0 }}
          >
            <img
              src={cdr_pic_2}
              border={"0px solid blue"}
              alt="Hero"
              style={heroPicStyle}
            ></img>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            px={2}
            border={"0px solid green"}
            alignSelf="center"
            className="hidden"
          >
            <Typography variant="h4" color={"#77C9E3"} align="left" pb={5}>
              CDR is unlocking Big Energy’s treasured customer data
            </Typography>
            <Typography align="left">
              Consumer Data Right (CDR) legislation will come into force in
              November 2022 and will require the big energy retailers to open up
              their most valuable asset: their retail customer database to their
              comptitors.
              <br />
              <br />
              Our platform helps energy companies use this gold mine of data to
              win customers away from the incumbents.
            </Typography>
          </Grid>
        </Grid>
        <Grid container display="flex" pb={10} border={"0px solid green"}>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            px={2}
            border={"0px solid green"}
            order={{ xs: 1, sm: 0 }}
            alignSelf="center"
            className="hidden"
          >
            <Typography variant="h4" color={"#77C9E3"} align="left" pb={5}>
              Energy prices are soaring
            </Typography>
            <Typography align="left">
              As energy prices skyrocket around the world, the need for better
              energy products is greater than ever.
              <br />
              <br />
              Our platform helps innovative energy retailers target, inform and
              acquire customers.
            </Typography>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            border={"0px solid red"}
            px={{ xs: 3, sm: 5 }}
            pb={{ xs: 5, sm: 0 }}
          >
            <img
              src={risingprices}
              border={"0px solid blue"}
              alt="Hero"
              style={heroPicStyle}
            ></img>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ImpactSection;
