import React from "react";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  Button,
  // TextField,
} from "@mui/material";
import house_background from "../assets/hero_background.png";
import "./HomeHeader.css";

function HomeHeader() {
  return (
    <Box
      style={{
        backgroundImage: `url(${house_background})`,
        backgroundSize: "100% 100%",
        height: "100vh",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{ border: "0px solid pink", height: "100%" }}
      >
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent={"left"}
          border={"0px solid blue"}
          height="100%"
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            border={"0px solid red"}
            textAlign="left"
          >
            <Stack display="flex">
              <Typography
                variant="h2"
                align="left"
                pb={2}
                border={"0px solid red"}
              >
                Powering the renewable transition
              </Typography>
              <Typography
                fontWeight={400}
                color="#FFFFFF"
                fontSize="20px"
                lineHeight="22px"
                align="left"
              >
                Our software platform gives renewable energy businesses the
                tools they need to win and retain customers.
              </Typography>
              <Grid container py={5}>
                {/* <Grid item border={"0px solid red"}>
                  <Button
                    variant="contained"
                    sx={{ py: 1, px: 10, borderRadius: 5, my: 2, mr: 5 }}
                    sm={12}
                    md={12}
                  >
                    Get in Touch
                  </Button>
                </Grid> */}
                <Grid item border={"0px solid red"} className="hidden">
                  <Button
                    variant="outlined"
                    sx={{ py: 1, px: 10, borderRadius: 5, my: 2, mr: 5 }}
                    sm={12}
                    md={12}
                    href={`mailto:${"hello@gleen.au"}?subject=${"Demo Enquiry"}&body=${""}`}
                  >
                    Schedule a Demo
                  </Button>
                </Grid>
              </Grid>
              {/* </Stack> */}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default HomeHeader;

// {/* <Stack
// direction="row"
// border={"0px solid red"}
// spacing={0}
// py={8}
// >
// <div className="TextField-without-border-radius">
//   {/* TODO: fix this - color goes infront of submitted text */}
//   <TextField
//     id="outlined-basic"
//     label="Your email address"
//     variant="outlined"
//   />
// </div>

// <Button
//   variant="contained"
//   href="#planscard"
//   size="large"
//   sx={{
//     px: 5,
//     borderTopRightRadius: 50,
//     borderBottomRightRadius: 50,
//   }}
// >
//   Sign Up
// </Button>
// </Stack> */}
