import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Home } from "./pages";
import { Navbar } from "./components";

import { ThemeProvider } from "@mui/material/styles";

import "@fontsource/rubik/300.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/700.css";
import "@fontsource/rubik/800.css";
import "@fontsource/rubik/900.css";
import "./App.css";
import { CssBaseline } from "@mui/material";
import { theme } from "./theme";
import { useEffect } from "react";

import "./components/animations.css";

function App() {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      console.log("hello");
      entries.forEach((entry) => {
        console.log(entry);
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <CssBaseline />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} exact />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
