import React from "react";
import { Box, Container, Grid, Typography, Fade } from "@mui/material";
import energy_dashboard_demo from "../assets/energy_dashboard_demo.png";
import gleen_para_icon from "../assets/gleen_para_icon.png";

function FeatureSection() {
  let heroPicStyle = {
    maxWidth: "100%",
    height: "auto",
    padding: 0,
    margin: 0,
  };
  return (
    <Box style={{ backgroundColor: "#F3FCFF" }} pt={20}>
      <Container maxWidth="lg">
        <Grid
          container
          display="flex"
          justifyContent="center"
          border={"0px solid green"}
        >
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            // pr={10}
            // pl={10}
            // pb={10}
            px={5}
            border={"0px solid blue"}
            order={{ xs: 1, sm: 0 }}
          >
            <img
              src={energy_dashboard_demo}
              alt="platform demo"
              style={heroPicStyle}
              border="0px solid black"
            />
          </Grid>
          <Fade in={true} appear={10}>
            <Grid
              border={"0px solid green"}
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              display="flex"
              alignItems="center"
              px={3}
            >
              <Grid
                item
                border={"0px solid red"}
                align="left"
                className="hidden"
              >
                <img src={gleen_para_icon} alt="sales icon" />
                <Typography variant="h3" align="left" mb={4} mt={2}>
                  Retain customers and find upselling opportunities
                </Typography>
                <Typography
                  fontWeight={400}
                  color="#2B84A1"
                  fontSize="20px"
                  lineHeight="22px"
                  align="left"
                  pr={5}
                  pb={5}
                >
                  Give your customers the insights they need to navigate the
                  energy transition.
                  <br />
                  <br />
                  Keep customers engaged with an insightful data dashboard that
                  shows customers where their energy comes from, and where it is
                  going.
                  <br />
                  <br />
                  Upsell customers to alternative energy products like solar,
                  batteries and electric vehicles.
                </Typography>
              </Grid>
            </Grid>
          </Fade>
        </Grid>
      </Container>
    </Box>
  );
}

export default FeatureSection;
