import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import bc_demo from "../assets/bc_demo.png";
import gleen_para_icon from "../assets/gleen_para_icon.png";

function PlatformExplanation() {
  let heroPicStyle = {
    maxWidth: "100%",
    height: "auto",
    padding: 0,
    margin: 0,
  };

  return (
    <Box style={{ backgroundColor: "#F3FCFF" }} pt={15}>
      <Container maxWidth="lg">
        <Grid
          container
          display="flex"
          justifyContent="center"
          border={"0px solid red"}
        >
          <Grid
            border={"0px solid green"}
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            display="flex"
            alignItems="center"
            px={3}
          >
            <Grid
              item
              border={"0px solid red"}
              align="left"
              // border={"1px solid red"}
              class="hidden"
            >
              <img src={gleen_para_icon} alt="gleen icon" />
              <Typography variant="h3" align="left" mb={4} mt={2}>
                Show customers how they’ll save
              </Typography>
              <Typography
                fontWeight={400}
                color="#2B84A1"
                fontSize="20px"
                lineHeight="22px"
                align="left"
                pr={5}
                pb={5}
              >
                Forget the spreadsheets and calculator. Our bill comparison
                software is the best in the industry.
                <br />
                <br />
                Deliver real-time, accurate, branded product comparisons.
                <br />
                <br />
                Use your competitors own data to win their customers with
                Consumer Data Right.
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={6} md={5} border={"0px solid blue"} pl={1} pr={2}>
            <img
              src={bc_demo}
              border={"0px solid blue"}
              alt="platform demo"
              style={heroPicStyle}
            ></img>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default PlatformExplanation;
